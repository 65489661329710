@import "../../vars.css";

.dropdownItem {
    padding: 5px;
    margin: 5px;

    cursor: pointer;
    border-radius: var(--border-radius);
    color: var(--color-text);

    text-align: right;
}

.dropdownItem:hover {
    color: var(--color-main);
}

.dropdownItemWarning {
    padding: 5px;
    margin: 5px;

    cursor: pointer;
    border-radius: var(--border-radius);
    color: var(--color-warning);

    text-align: right;
}

.dropdownItemWarning:hover {
    color: var(--color-main);
}