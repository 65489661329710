@import "../../vars.css";

.container {  
    padding-top: 30px;
    padding-bottom: 5px;

    min-height: 396px;
}

.sectionTitle {
    margin: 0;
    padding-bottom: 9px;

    font-size: var(--font-size-large);
    text-align: left;
    color: var(--color-main);

    border-bottom: solid var(--color-main);

}

.header {
    display: grid;
    grid-template-columns: 2fr 1fr;

    border-bottom: solid var(--color-main);

}

.pageSelector {
    align-self: last baseline;
    justify-self: right;
}

.link {
    text-decoration: none;
    color: var(--color-main);
    margin-bottom: 5px;
    align-self: last baseline;
    text-align: right;
}

.dropdown {
    margin-bottom: 5px;
}

@media screen and (max-width: 500px) {
    
    .container {
        padding-top: 10px;

        width: 325px;
        padding-left: 5px;
        min-height: 321px;
    }

}