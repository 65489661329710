@import "../../../vars.css";

.container {

    margin-top: 10px;
    margin-left: 15px;

    max-width: 400px;
}

.content {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    gap: 5px;
}

.activeGenre {

    border: none;
    border-radius: var(--border-radius-large);
    background-color: var(--color-mainLight);

    color: var(--color-mainDark);

    font-size: var(--font-size-tiny);
    font-weight: 550;
    font-family: "SanFransico";

    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 6px;
    padding-right: 6px;

    text-align: center;

    cursor: pointer; 
}

.inactiveGenre {
    border: none;
    border-radius: var(--border-radius-large);
    background-color: var(--color-grayLight);

    color: var(--color-mainDark);
    border: none;

    font-size: var(--font-size-tiny);
    font-weight: 550;
    font-family: "SanFransico";

    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 6px;
    padding-right: 6px;

    text-align: center;

    cursor: pointer;
}

.inactiveGenre:hover {
    background-color: var(--color-gray);
}

@media screen and (max-width: 500px) {

    .content {
        grid-template-columns: 100px 100px;
        row-gap: 10px;
    }
}