@import "../../vars.css";

.container {
    max-width: var(--max-width);

    margin-top: 50px;
    margin-bottom: 50px;

    margin-left: auto;
    margin-right: auto;
    
    display: flex;
    flex-direction: column; 
}

.content {
    margin-left: 5%;
    margin-right: 5%;

}


.header {
    border-bottom: solid var(--color-main);

    display: grid;
    grid-template-columns: 1fr 2fr;

    margin-left: 5%;
    margin-right: 5%;

    padding-bottom: 5px;
    
}

.librarySelector {
    z-index: 2;
}

.typeSelectors {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: var(--grid-gap-small);

    margin-left: 5%;
    margin-right: 5%;

    margin-bottom: 20px;
}


.activeType {

    border: none;
    border-radius: var(--border-radius-large);
    background-color: var(--color-mainLight);

    color: var(--color-mainDark);

    font-size: var(--font-size-tiny);
    font-weight: 550;
    font-family: "SanFransico";

    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 6px;
    padding-right: 6px;

    text-align: center;

    cursor: pointer;

}

.inactiveType {

    border: none;
    border-radius: var(--border-radius-large);
    background-color: var(--color-grayLight);

    color: var(--color-mainDark);
    border: none;

    font-size: var(--font-size-tiny);
    font-weight: 550;
    font-family: "SanFransico";

    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 6px;
    padding-right: 6px;

    text-align: center;

    cursor: pointer;
}

.inactiveType:hover {
    background-color: var(--color-gray);
}

.pageSelector {
   align-self: last baseline;
   justify-self: right;
}

.mobilePageSelector {
    display: flex;
    flex-direction: row;

    align-items: center;
}

.soryByHeader {
    color:  var(--color-main);
    font-size: var(--font-size-mediumSmall);
    font-weight: 600;

    margin-right: 10px;

    padding-top: 10px;
    padding-bottom: 10px;
}

.sortBySelector {
    margin-left: 5%;
    margin-bottom: 10px;

    margin-top: 10px;

    display: flex;
    align-items: center;
}

.mobilePage {
    color: var(--color-main);
}

.buttonContainer {
    display: flex;
    flex-direction: row;

    margin-left: 5%;
    margin-right: 5%;

    justify-content: space-between;
    
}

.carrot {
    height: 20px;
}


.carrotNextButton {
    background-color: transparent;
    
    border: none;
    border-radius: 15px;

    padding: 5px;

    width: 35px;
    
}

.carrotBackButton {
    background-color: transparent;
    
    border: none;
    border-radius: 15px;

    padding: 5px;

    width: 35px;

}

.emptyShelf {
    margin: 25px;
    padding: 20px;
    
    height: 250px;

    font-size: 18px;
    color: var(--color-text);

    grid-column-start: 1;
    grid-column-end: span 3;

    grid-row-start: 1;
    grid-row-end:span 2;
}

.loader {
    margin-top: 20px;
    min-height: var(--min-height);
}

.carrotBackButton:hover {
    background-color: var(--color-grayLight);
}
.carrotNextButton:hover {
    background-color: var(--color-grayLight);
}

@media screen and (max-width: 830px) {

    .gridContainer {
        grid-template-columns: 1fr;
    }

}

@media screen and (max-width: 500px) {

    .container {
        margin-top: 30px;
    }

}

