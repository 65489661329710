@import "../../vars.css";

.container {
    display: flex;
    flex-direction: row;
    align-items: center;

    padding-left: 10px;

    background-color: var(--color-grayLight);
    
    border-radius: var(--border-radius);

    font-family: "SanFransico";
}

.icon {
    height: 20px;
    margin-right: 5px;
    cursor: pointer;
}

/* .input {
    width: 0;
    border: none;
    background-color: transparent;
} */

.input {
    width: 100%;
    height: 100%;

    border: none;
    background-color: transparent;

    font-size: 20px;
}

.input:focus{
    outline: none;
}

@media screen and (max-width: 1080px) {

    .input {
        width: 180px;
    }
}

@media screen and (max-width: 500px) {

    .input {
        font-size: var(--font-size-small);
        width: 175px;
    }
}