@import "../../vars.css";

/* .bigContainer {
    display: flex;
    flex-direction: column;

    width: 529.9px;
    min-width: 200px;
    height: 50px;

    background-color: var(--color-bg);
}

.container {
    display: flex;
    flex-direction: column;

    align-self: center;
    
    z-index: 1;

    width: fit-content;
    min-width: 200px;
}

.barAndDropdown {
    display: grid;
    grid-template-columns: 2fr 1fr;

    margin-top: 20px;

    box-shadow: var(--box-shadow);
    border-radius: var(--border-radius);
}


.dropdown {
    display: flex;
    justify-content: end;
}

@media screen and (max-width: 500px) {

    .bigContainer {
        width: 100%
    }

    

} */

.container {
    display: grid;
    /* grid-template-rows: 50px 1000px; */

    height: 40px;
    width: fit-content;
    align-items: center;
    justify-content: center;

    background-color: var(--color-grayLight);
    border-radius: var(--border-radius);

    margin-bottom: 10px;
}

.barANDdropdown {
    display: flex;
    flex-direction: row;

    width: fit-content;
}

.results {
    background-color: var(--color-bg);
    z-index: 2;
    width: 300px;

    margin-top: 10px;
}

@media screen and (max-width: 500px) {

    .container {
        height: 50px;
        grid-template-rows: 55px 1fr;
    }

    .results {
        margin-top: 0;
    }
}