@import "../../../vars.css";

.container {
    display: flex;
    flex-direction: column;

    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);

    margin-left: 10px;
    margin-right: 10px;

    padding-top: 5px;
    
    height: 245px;

}

@media screen and (max-width: 500px) {

    .container {
        height: 175px;
        padding-top: 0;

    }

}


