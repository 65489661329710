@import "../../vars.css";

.container {
    display: flex;

    margin-left: 5%;
    margin-right: 5%;

    justify-content: center;
}

.content {
    display: grid;
    grid-template-columns: 1fr 1fr;

    margin-top: 20px;
    gap: var(--grid-gap-small);
}

.suggestions {
    display: flex;
    flex-direction: column;
    align-items: center;

    box-shadow: var(--box-shadow);
    border-radius: var(--border-radius);
    
    margin-top: 10px;
    padding: 10px;

    max-width: 350px;
    height: fit-content;

    cursor: pointer;
}

.selection {
    margin-left: 10px;
    margin-top: 10px;

    display: flex;
    flex-direction: row;

    align-items: center;
}

.removeSelection {
    content: url("/public/assets/images/deleteIcon.svg");
    height: 20px;
    margin-left: 10px;
    cursor: pointer;

    margin-right: 45px;
}

.removeSelection:hover {
    content: url("/public/assets/images/deleteIconHover.svg");
    height: 20px;
    margin-left: 10px;
    cursor: pointer;

    margin-right: 45px;
}

.creatorSelections {
    display: flex;
    flex-direction: column;


    margin-top: 10px;
}

.creatorGroup {
    display: flex;
    flex-direction: row;

    align-items: center;

    margin-left: 10px;
}

.restricted {
    color: var(--color-text);
    font-weight: 700;

    display: grid;
    grid-template-columns: 200px 1fr;
}

.inputs {
    display: flex;
    flex-direction: column;
}

.label {
    margin-top: 10px;
    color: var(--color-text);

    justify-self: end;
    align-self: center;
}

.input {
    background-color: var(--color-grayLight);
    border-style: none;
    border-radius: var(--border-radius);

    padding: 10px;

    margin-left: 15px;
    margin-right: 15px;

    margin-top: 20px;

    font-family: "SanFransico";
    font-size: var(--font-size-tiny);

    width: 400px;
}

.input:focus {
    outline: none;
}

.creatorInputContainer {
    display: grid;
    grid-template-columns: 350px 30px;

    gap: 20px;

    align-items: center;

    margin-left: 15px;
    margin-right: 15px;

    margin-top: 20px;
}

.creatorInput {
    background-color: var(--color-grayLight);
    border-style: none;
    border-radius: var(--border-radius);

    padding: 10px;

    font-family: "SanFransico";
    font-size: var(--font-size-tiny);

    width: 350px;
}

.creatorInput:focus {
    outline: none;
}

.checkBox {
    height: 20px;
    margin-left: 15px;

    justify-self: start;
    align-self:self-end
}

.optionsContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;

    max-width: 400px;

    margin-left: 15px;
    margin-top: 10px;


    /* padding: 10px;
    margin-left: 5px; */

    gap: var(--grid-gap-small);
}

.activeOption {
    border: none;
    border-radius: var(--border-radius-large);
    background-color: var(--color-mainLight);

    color: var(--color-mainDark);

    font-size: var(--font-size-tiny);
    font-weight: 550;
    font-family: "SanFransico";

    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 6px;
    padding-right: 6px;

    text-align: center;

    cursor: pointer; 
}

.inactiveOption {
    border: none;
    border-radius: var(--border-radius-large);
    background-color: var(--color-grayLight);

    color: var(--color-mainDark);
    border: none;

    font-size: var(--font-size-tiny);
    font-weight: 550;
    font-family: "SanFransico";

    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 6px;
    padding-right: 6px;

    text-align: center;

    cursor: pointer;
}

.inactiveOption:hover {
    background-color: var(--color-gray);
}


.buttonContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;

    margin-top: 50px;
}


.button {
    border: none;
    border-radius: var(--border-radius);
    background-color: var(--color-grayLight);

    color: var(--color-mainDark);
    border: none;

    font-size: var(--font-size-tiny);
    font-weight: 550;
    font-family: "SanFransico";

    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 6px;
    padding-right: 6px;

    text-align: center;

    cursor: pointer;

    width: 100px;

}

.saveButton {
    border: none;
    border-radius: var(--border-radius);
    background-color: var(--color-grayLight);

    color: var(--color-mainDark);
    border: none;

    font-size: var(--font-size-tiny);
    font-weight: 550;
    font-family: "SanFransico";

    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 6px;
    padding-right: 6px;

    text-align: center;

    cursor: pointer;

    width: 100px;

    justify-self: end;
}

.button:hover {
    background-color: var(--color-gray);
}

.saveButton:hover {
    background-color: var(--color-gray);
}

.addButton {
    width: 30px;
    height: 38px;

    font-size: var(--font-size-tiny);
    color: var(--color-text);

    border: none;
    border-radius: var(--border-radius);
}

.MOBILEsuggestions {
    display: grid;
    grid-template-columns: 75px 1fr;
}

@media screen and (max-width: 500px) {
    
    .creatorInputContainer {
        grid-template-columns: 150px 30px;
    }
    .creatorInput {
        width: 150px;
        font-size: var(--font-size-small);
    }

    .input {
        width: 200px;
    }

    .optionsContainer {
        display: flex;
        flex-direction: column;

        width: 200px;
    }

    .label {
        width: 100px;
        text-align: right;
    }

    .suggestions {
        grid-column: 2;
    }

    .restricted {
        grid-template-columns: 125px 1fr;
    }
}