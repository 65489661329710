@import '../../vars.css';

.container {
    max-width: var(--max-width);
    min-height: 375px;

    margin-left: auto;
    margin-right: auto;

    margin-top: 50px;
    margin-bottom: 50px;
}

.content {
    margin-left: 5%;
    margin-right: 5%;

    margin-top: 15px;
    
    border-radius: var(--border-radius);
}

.header {
    display: grid;
    grid-template-columns: 2fr 1fr;

    margin-left: 5%;
    margin-right: 5%;

    border-bottom: solid var(--color-main);


}
.pageSelector {
    align-self: last baseline;
    justify-self: right;
}

.gridContainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 15px;

    margin-left: 10px;
    margin-right: 15px;
}

.book {
    align-content: stretch;
}

.sectionTitle {
    padding: 7px;

    font-size: var(--font-size-large);
    text-align: left;
    color: var(--color-main);
    font-weight: 550;

}
.emptyShelf {
    margin: 25px;
    padding: 20px;
    
    height: 250px;

    font-size: 18px;
    color: var(--color-text);

    grid-column-start: 1;
    grid-column-end: span 3;

    grid-row-start: 1;
    grid-row-end:span 2;
}

.loader {
    grid-column: span 3;
    margin-top: 50px;
}

@media screen and (max-width: 1204px) {
    .gridContainer {
        grid-template-columns: 1fr 1fr;
    }
}

@media screen and (max-width: 800px) {
    .gridContainer {
        grid-template-columns: 1fr;
    }
}

@media screen and (max-width: 500px) {

    .container {
        width: 325px;
        margin-top: 0px;
    }

    .content {
        display: flex;
        flex-direction: column;

        align-items: center;
        margin-top: 15px;
    }

    .header {
        margin: 0;
    }

    .sectionTitle {
        font-size: var(--font-size-mediumLarge);
    }

    .gridContainer {
        margin-left: 0;
        margin-right: 0;
    }
}