@import "../../vars.css";

.container {
    margin-top: 50px;

    margin-right: auto;
    margin-left: auto;

    max-width: var(--max-width);
}

.contentEXP {
    margin-left: 5%;
    margin-right: 5%;

    display: grid;
    grid-template-columns: 1fr 1fr;

    gap: 10px;
}

.contentCOVER {
    margin-left: 5%;
    margin-right: 5%;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;

    gap: 10px;
}


.header { 
    display: grid;
    grid-template-columns: 2fr 1fr;
    
    margin-top: 50px;

    margin-left: 5%;
    margin-right: 5%;
 
    padding: 5px;
    padding-bottom: 10px;

    font-weight: 900;

    border-bottom: solid var(--color-main);
    color: var(--color-main);

    font-size: var(--font-size-large);
    text-align: left;
    font-weight: 550;
}

.pageSelector {
    align-self: last baseline;
    justify-self: right;
}


.bottomPageSelector {
    display: flex;

    margin-right: 5%;
    margin-left: 5%;
    
    margin-top: 20px;

    justify-content: space-between;

}

.genreContainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: var(--grid-gap-small);

    margin-bottom: 20px;
}



.typeContainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;

    gap: var(--grid-gap-small);

    margin-bottom: 20px;
}

.activeTypeOrGenre {

    border: none;
    border-radius: var(--border-radius-large);
    background-color: var(--color-mainLight);

    color: var(--color-mainDark);

    font-size: var(--font-size-tiny);
    font-weight: 550;
    font-family: "SanFransico";

    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 6px;
    padding-right: 6px;

    text-align: center;

    cursor: pointer;

}

.inactiveTypeOrGenre {

    border: none;
    border-radius: var(--border-radius-large);
    background-color: var(--color-grayLight);

    color: var(--color-mainDark);
    border: none;

    font-size: var(--font-size-tiny);
    font-weight: 550;
    font-family: "SanFransico";

    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 6px;
    padding-right: 6px;

    text-align: center;

    cursor: pointer;
}

.inactiveTypeOrGenre:hover {
    background-color: var(--color-gray);
}

.emptyShelf {
    margin-top: 20px;

    color: var(--color-text);
    font-size: var(--font-size-mediumLarge);
    font-weight: 600;

    grid-column: 1 / span all;
}

.loader {
    margin-top: 20px;
    height: var(--min-height);

    justify-self: center;
    grid-column: 1 / span all;
}

.options {
    display: grid;
    grid-template-columns: 2fr 1fr;

    margin-left: 5%;
    margin-right: 5%;
}

.sizeTitle {
    margin-right: 10px;
}

.sizeSelector {
    display: flex;
    flex-direction: row;

    margin-bottom: 10px;
    margin-top: 10px;

    color: var(--color-main);
    font-weight: 600;
    font-size: var(--font-size-mediumSmall);

    align-items: center;
    justify-content: start;
}

.filterSelectorContainer {
    display: flex;
    flex-direction: row;

    justify-content: end;
    align-items: center;

    color: var(--color-main);
    font-weight: 600;
    font-size: var(--font-size-mediumSmall);
}

/* @-webkit-keyframes fadeIn {
    from { opacity: 0; }
      to { opacity: 1; }
} */
@keyframes fadeIn {
    from { opacity: 0; }
      to { opacity: 1; }
}


/* @keyframes fadeOut {
    from {opacity: 1;}
    to {opacity: 0;}
} */

.filterContainer {
    margin-left: 5%;
    margin-right: 5%;

    margin-bottom: 25px;

    padding: 20px;

    box-shadow: var(--box-shadow);
    border-radius: var(--border-radius);

    /* -webkit-animation: fadeIn 100ms; */
    animation: fadeIn 100ms;
}


.filterHeader {
    color: var(--color-main);
    font-weight: 600;
    font-size: var(--font-size-small);
    border-bottom: solid var(--color-main) 1px;

    text-align: left;

    margin-bottom: 10px;
}

.filterTitleContainer {
    display: flex;
    align-items: center;

    cursor: pointer;
}


.filterTitle {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 10px;

    padding-left: 8px;
}

.filterCircle {
    font-size: var(--font-size-tiny);
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;

    padding-right: 8px;

    animation: fadeIn 100ms;
}

.carrot {
    height: 20px;
    cursor: pointer;
}

.carrotNextButton {
    background-color: transparent;
    
    border: none;
    border-radius: 15px;

    padding: 5px;

    width: 35px;
}

.carrotBackButton {
    background-color: transparent;
    
    border: none;
    border-radius: 15px;

    padding: 5px;

    width: 35px;
}
.carrotBackButton:hover {
    background-color: var(--color-grayLight);
}
.carrotNextButton:hover {
    background-color: var(--color-grayLight);
}

.loader {
    height: 500px;
    margin-top: 50px;
}

@media screen and (max-width: 500px) {

    .contentCOVER {
       grid-template-columns: 1fr 1fr;
    }

    .contentEXP {
        grid-template-columns: 1fr;
    }

    .genreContainer {
        grid-template-columns: 1fr 1fr 1fr;
    }

    .header {
        font-size: var(--font-size-mediumLarge);
    }

    .filterTitle {
        font-size: var(--font-size-small);
    }

    .sizeTitle {
        font-size: var(--font-size-small);
    }

    .typeContainer {
        grid-template-columns: 1fr 1fr 1fr;
    }

}