@import "../../../vars.css";

.container {
    display: grid;
    grid-template-columns: 200px 1fr;

    align-items: center;
    justify-content: end;
}

.label {
    margin-top: 10px;
    color: var(--color-text);

    justify-self: end;
    text-align: right;
}

.input {
    background-color: var(--color-grayLight);
    border-style: none;
    border-radius: var(--border-radius);

    padding: 10px;

    margin-left: 15px;
    margin-right: 15px;

    margin-top: 20px;

    font-family: "SanFransico";
    font-size: var(--font-size-tiny);

    width: 400px;
}

.input:focus {
    outline: none;
}


@media screen and (max-width: 500px) {

    .container {
        grid-template-columns: 125px 1fr;
        
    }

    .input {
        width: 200px;
        font-size: var(--font-size-small);
    }

    .label {
        width: 100px;
    }
}