@import "../../vars.css";

.container {
    background-color: var(--color-mediumlight);

    margin-top: 30px;
    
    margin-right: 5%;
    margin-left: 5%;

    width: 100%;
}

.header {
    display: grid;
    grid-template-columns: 5fr 1fr;

    margin-top: 10px;

    box-shadow: var(--box-shadow);
    border-top: solid var(--color-main);
    background-color: var(--color-mediumlight);
    
    
}

.headerTitle {
    color: var(--color-main);
    text-align: left;
    font-size: var(--font-size-mediumLarge);
    font-weight: 550;

    padding: 20px;
}

.plusButton {
    background-color: transparent;
    border: none;

    justify-self: end;

    cursor: pointer;

    padding: 20px;
}

.icon {
    margin-left: 10px;
    margin-right: 15px;
    height: 20px;
}

.content {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    gap: 15px;
}


.loader {
    margin-top: 20px;
}




@media screen and (max-width: 1482px) {

    .content {
        grid-template-columns: 1fr 1fr;
    }
}

@media screen and (max-width: 998px) {

    .content {
        grid-template-columns: 1fr;
    }
}


@media screen and (max-width: 500px) {

    .container {
        width: 325px;
    }

    .header {
        width: 325px;
    }
}