@import "../../vars.css";

.link {
    text-decoration: none;
    color: var(--color-text);
}


/* small */

.containerS {
    height: 115px;
}

.bookCoverS {
    height: 100px;
    width: 63px;

    object-fit: cover;

    margin: 12px;

    border-radius: 5px;


}


/* medium */

.containerM {
    height: 165px;
}

.bookCoverM {
    height: 150px;
    width: 94px;

    object-fit: cover;

    margin: 12px;

    border-radius: 5px;
}


/* large */

.containerL {
    height: 215px;
}

.bookCoverL {
    height: 200px;
    width: 125.3333px;

    object-fit: cover; 

    margin: 12px;

    border-radius: 5px;
}

/* extra large */

.containerXL {
    position: relative;
    height: 315px;
}

.bookCoverXL {
    height: 300px;
    width: auto;

    border-radius: 5px;
}


.upload {
    height: 20px;
    position: absolute;

    right: 20px;
    bottom: 30px;

    cursor: pointer;
}

.input {
    display: none;
}
