@import "../../vars.css";

.container {
    border-top: solid var(--color-main);
    box-shadow: var(--box-shadow);

    display: flex;
    flex-direction: column;

    align-items: start;

    margin-top: 50px;
    margin-left: 5%;
    margin-right: 15px;

    min-height: 335px;
    width: 330px;

}

.mobileContainer {
    border-top: solid var(--color-main);
    box-shadow: var(--box-shadow);

    display: flex;
    flex-direction: column;

    margin-top: 30px;
    margin-left: 5%;
    margin-right: 15px;

    min-height: 335px;
    width: 325px;

}

.mobileCoverAndInfo {
    display: flex;
    flex-direction: row;
}

.triangle {
    margin: 0;
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 40px 40px 0 0;
    border-color: var(--color-main) transparent transparent transparent;
    transform: rotate(0deg);

    position: absolute;
    z-index: 3;
}

.triangleBookCover {
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 40px 40px 0 0;
    border-color: var(--color-bg) transparent transparent transparent;
    transform: rotate(0deg);

    position: absolute;
}

.addToLibraryButton {
    background-color: var(--color-grayLight);
    color: var(--color-text);
    border: none;
    border-radius: var(--border-radius);

    font-size: var(--font-size-mediumSmall);
    font-weight: 600;
    font-family: "SanFransico";

    margin-bottom: 10px;

    padding: 10px;

    width: 200px;

    cursor: pointer;
}

.addToLibraryButton:hover {
    background-color: var(--color-mainLight);
}

.bookCover {
    margin: 15px;
}

.coverImageAndButton {
    display: flex;
    flex-direction: column;

    width: 350px;
    align-items: center;
}

.infoAndDescription {
    width: 100%;
    padding: 10px;

    margin-right: 15px;
}

.mediaInfo {
    padding-left: 20px;
    margin-bottom: 25px;
}

.titleAndSeries {
    margin-right: 7px;
    text-align: left;
}

.bookSeries {
    color: var(--color-mainLight);
    margin-top: 7px;
}

.titleContainer {
    margin-top: 5px;
}

.favoriteButton {
    background-color: transparent;
    border: none;

    font-family: "SanFransico";
    text-align: left;


    cursor: pointer;
}

.firstWordsOfTitle {
    color: var(--color-main);

    font-family: "SanFransico";
    font-weight: 600;
    text-align: left;
    font-size: var(--font-size-mediumLarge);
}

.lastWordOfTitle {
    color: var(--color-main);
    white-space: nowrap;

    font-family: "SanFransico";
    text-align: left;
    font-weight: 600;
    font-size: var(--font-size-mediumLarge);
}

.starIconFilled {
    content: url("/public/assets/images/fullFilledStar.svg");
    height: 25px;
    margin-left: 15px;
}

.starIconEmpty {
    content: url("/public/assets/images/fullEmptyStar.svg");
    height: 25px;
    margin-left: 15px;
    cursor: pointer;
}

.starIconEmpty:hover {
    background-color: var(--color-grayVeryLight);
    border-radius: 5px;
}


.link {
    text-decoration: none;
}

.infoTitle {
    padding: 10px;
    font-size: var(--font-size-mediumSmall);
    text-align: left;
}

.mobileDescription {
    padding: 10px;
}

.spacer {
    height: 12px;
}

.readMoreOrLessButton {
    background-color: transparent;
    color: var(--color-main);

    border: none;
    border-radius: 15px;

    padding: 5px;

}

.readMoreOrLessButton:hover {
    background-color: var(--color-grayLight);
}

.carrot {
    height: 20px;
}

@media screen and (max-width: 830px) {

    .yourBookInfoContainer {
        width: max-content;
    }
}

@media screen and (max-width: 500px) {

    .bookInfo {
        display: flex;
        flex-direction: column;
        
        padding-top: 15px;

        margin-bottom: 0;
    }

    .bookCover {
        margin: 10px;
    }

    .coverImageAndButton {
        width: 150px;
    }

    .authorContainer {
        text-align: left;
    }

    .firstWordsOfTitle {
        font-size: var(--font-size-mediumSmall);
    }

    .lastWordOfTitle {
        font-size: var(--font-size-mediumSmall);
    }

    .starIconFilled {
        height: 20px;
    }
    .starIconEmpty {
        height: 20px;
    }
    .starIconEmpty:hover {
        height: 20px;
    }

    .addToLibraryButton {
        width: 82px;
        font-size: var(--font-size-tiny);
    }
}