@import "../../vars.css";

.container {
    padding-top: 25px;
    padding-bottom: 5px;
    
    min-height: 400px;
}

.content {
    border-radius: var(--border-radius);

    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: var(--grid-gap)
}

.sectionTitle {
    margin-bottom: 15px;
    padding: 5px;

    font-size: var(--font-size-large);
    font-weight: 550;
    text-align: left;
    color: var(--color-main);

    border-bottom: solid var(--color-main);
}

.emptyShelf {
    margin: 20px;
    padding: 20px;
    
    height: 250px;

    font-size: var(--font-size-mediumSmall);
    color: var(--color-text);
}

@media screen and (max-width: 1060px) {
    .content {
        min-height: 200px;
        grid-template-columns: 1fr;
    }

}

@media screen and (max-width: 500px) {

    .container {
        padding-top: 0px;
        
        padding-left: 5px;
        min-height: 300px;
    }

    .sectionTitle {
        font-size: var(--font-size-mediumLarge);
    }

}


