@import "../../vars.css";

.dropdownButtonOpen {
    display: flex;
    align-items: center;

    justify-content: end;

    width: fit-content;

    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 5px;
    padding-left: 10px;

    background-color: transparent;

    cursor: pointer;

    border-radius: var(--border-radius);
    border-style: none;

    font-size: var(--font-size-mediumSmall);
    font-weight: 600;
    font-family: "SanFransico";
    color: var(--color-main);
    text-align: left;
}

.dropdownButtonClosed {
    display: flex;
    align-items: center;

    justify-content: end;

    width: fit-content;

    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 5px;
    padding-left: 10px;

    background-color: transparent;


    cursor: pointer;

    border-radius: var(--border-radius);
    border-style: none;

    font-size: var(--font-size-mediumSmall);
    font-weight: 600;
    font-family: "SanFransico";
    color: var(--color-main);
    text-align: left;
}

.carrot {
    height: 24px;

    display: flex;
    align-items:center;

    margin-left: 15px;

}

@media screen and (max-width: 500px) {

    .dropdownButtonClosed {
        font-size: var(--font-size-small);
        min-width: 100px;

        padding-top: 0;
        padding-bottom: 0;
    }
    .dropdownButtonOpen {
        font-size: var(--font-size-small);
        min-width: 100px;
        
        padding-top: 0;
        padding-bottom: 0;

    }

}

