@import "../../vars.css";

.container {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;

    max-width: var(--max-width);
}

.content {
    margin-left: 5%;
    margin-right: 5%;

    margin-top: 50px;

}

.sectionTitle {
    margin: 0;
    padding: 5px;

    font-size: var(--font-size-large);
    text-align: left;
    color: var(--color-main);
    font-weight: 550;

    border-bottom: solid var(--color-main);
}

.accountDetails {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;

    padding: 10px;

    color: var(--color-text);
    box-shadow: var(--box-shadow);
    
}

.subTitle {
    padding: 10px;
    color: var(--color-mainDark);
    text-align: left;
}

.input {
    background-color: var(--color-grayLight);
    border-style: none;
    border-radius: 5px;

    padding: 10px;

    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 20px;

    font-family: "SanFransico";
    font-size: var(--font-size-tiny);

    text-align: left;
}

.input:focus {
    outline: none;
}

.buttonContainer {
    display: grid;
    justify-content: end;
}

.button {
    background-color: var(--color-grayLight);
    border: none;
    border-radius: var(--border-radius);

    color: var(--color-warning);

    margin-top: 20px;
    margin-left: 15px;
    margin-right: 15px;

    padding: 10px;

    width: 150px;

    cursor: pointer;
}


.button:hover {
    background-color: var(--color-gray);
}

@media screen and (max-width: 500px) {
    .sectionTitle {
        font-size: var(--font-size-mediumLarge);
    }

    .content {
        margin-top: 30px;
    }

    .buttonContainer {
        justify-content: center;
    }
}