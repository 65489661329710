@import "../../../vars.css";

.container {
    display: flex;
    flex-direction: row;

    align-items: start;

    padding: 18px;

    margin-bottom: 10px;

    box-shadow: var(--box-shadow);

    height: 100%;

}

.content {
    display: flex;
    flex-direction: row;
}

.link {
    text-decoration: none;
}

.bookInfo {
    margin-top: 7px;
    margin-left: 5px;

    text-align: left;

}

.bookTitle {
    font-size: var(--font-size-mediumSmall);
    text-align: left;

    margin-bottom: 3px;

    text-decoration: none;
    color: var(--color-main)

}

.bookSeries {
    text-align: left;
    font-size: var(--font-size-small);

    margin-bottom: 3px;

    color: var(--color-mainLight)
}

.tags {
    display: flex;
    flex-direction: column;
}

.tagsInBook {
    display: flex;
    flex-direction: column;
}

.tagsInBookClosed {
    display: flex;
    flex-direction: column;

    overflow: hidden;
}

.readMoreOrLessButton {
    align-items: center;
    background-color: transparent;

    border: none;
    border-radius: 15px;

    padding: 5px;

    width: 30px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5px;
    
}

.readMoreOrLessButton:hover {
    background-color: var(--color-grayLight);
}

.carrot {
    height: 20px;
}

@media screen and (max-width: 500px) {

    .container {
        width: 300px;
        
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 5px;
        padding-right: 10px;
    }
}