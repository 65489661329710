@import "../../vars.css";

.container {
    margin-top: 100px;
}

.content {
    margin-left: auto;
    margin-right: auto;

    min-height: var(--min-height);

    width: 400px;

    text-align: center;

}

.logo {
    height: 150px;
    margin-bottom: 50px;
}

.sectionHeader{
    color: var(--color-main);
    
    font-size: var(--font-size-large);

    padding: 5px;
}

.form {
    box-shadow: var(--box-shadow);

    padding: 25px;
    border-top: solid var(--color-main);

}

.labelHeader {
    margin-left: 15px;

    font-size: var(--font-size-mediumSmall);
    color: var(--color-text);
    text-align: left;

    margin-bottom: 25px;
}


.inputBox {
    height: 32px;
    width: 325px;

    background-color: var(--color-grayLight);
    border-style: none;
    border-radius: var(--border-radius);

    font-family: "SanFransico";

    text-indent: 5px;
}

.inputBox:focus {
    outline: none;
}

.info {
    margin-top: 5px;
    margin-bottom: 5px;

    font-size: var(--font-size-tiny);
    font-weight: 400;
}

.badInputMessage {
    color: var(--color-warning);
    margin-top: 5px;
    margin-bottom: 5px;
}

.badInput {
    margin-bottom: 25px;
    
    height: 32px;
    width: 325px;

    background-color: var(--color-warningLight);
    border-style: none;
    border-radius: var(--border-radius);

    font-family: "SanFransico";

    text-indent: 5px;

}

.buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;

    margin-left: 15px;
    width: 325px;

    justify-content: space-between;
}


.submitButton {
    background-color: var(--color-grayLight);
    border: none;
    border-radius: var(--border-radius);

    margin-top: 10px;
    margin-left: 15px;
    margin-right: 15px;

    padding: 10px;

    width: 100px;

    cursor: pointer;
}

.submitButton:hover {
    background-color: var(--color-gray);
}

.forgotButton {
    background-color: transparent;
    border: none;
    cursor: pointer;

    font-size: var(--font-size-small);
}

.noAccount {
    color: var(--color-text);
    margin-top: 20px;
    margin-bottom: 100px;

    font-size: var(--font-size-tiny);
}

.registerButton {
    background-color: transparent;
    color: var(--color-text);

    border: none;

    margin-left: 5px;
    
    font-size: var(--font-size-tiny);
    font-family: "SanFransico";
    font-weight: 600;
    cursor: pointer;
}

.registerEnquire {
    color: var(--color-text);
    margin-top: 20px;
    margin-bottom: 100px;

    font-size: var(--font-size-mediumSmall);
}

.failer {
    color: var(--color-warning);
}

@media screen and (max-width: 500px) {

    .content {
        width: 300px;
    }

    .inputBox {
        width: 225px;
        font-size: var(--font-size-small);
    }
}