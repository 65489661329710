@import "../../vars.css";

.container {

    display: flex;
    flex-direction: column;

    justify-content: space-between;

    border-top: solid var(--color-main);
    box-shadow: var(--box-shadow);

    margin-top: 50px;
    margin-right: 5%;
    margin-left: 15px;

    padding: 10px;

    width: 350px;
}

.header {
    margin-bottom: 5px;
    color: var(--color-mainDark);
}

.value {
    margin-bottom: 15px;
    color: var(--color-text);
}

.genre {
    color: var(--color-text);
}

.genreList {
    display: flex;
    flex-direction: column;
}

.editMediaContainer {
    display: flex;
    justify-content: end;

    margin-right: 15px;

    cursor: pointer;
}

.icon {
    height: 25px;
}

@media screen and (max-width: 500px) {

    .container {
        width: 325px;
        margin-left: 5%;
        margin-top: 30px;
    }
}