@import "../../vars.css";

.container {
    display: flex;

    height: 60px;
    background-color: var(--color-main);
    justify-content: center;

}


.content {
    width: var(--max-width);
    font-size: var(--font-size-mediumLarge);

    display: grid;
    grid-template-columns: 1fr 1fr;
}

.links {
    margin-left: 10%;

    display: flex;
    flex-direction: row;
}

.searchAndProfile {
    margin-right: 10%;

    display: flex;
    justify-content: end;

    padding-top: 10px;
}

.profile {
    margin-top: 5px;
    margin-left: 20px;
}

.activeNavbarLink {
    text-decoration: none;
    color: var(--color-grayLight);

    font-weight: 900;

    margin-right: 20px;

    padding-top: 10px;
}

.navbarLink {
    text-decoration: none;
    color: var(--color-mainLight);

    font-weight: 900;

    margin-right: 20px;
    padding-top: 10px;
}


.icon {
    height: 60px;
    margin-right: 10px;
}

/* MOBILE */

.mobileLinksContainer {
    display: grid;
    grid-template-columns: 50px 1fr;

    width: 100%;

    padding: 10px;
}

.mobileLinks {
    display: flex;
    flex-direction: row;

    justify-self: end;

    margin-top: 5px;

    cursor: pointer;

}

.profileLink {
    text-decoration: none;
    color: var(--color-mainLight);
    font-size: var(--font-size-large);

    font-weight: 600;

    margin-right: 10px;
    margin-top: 30px;
}

.mobileIcon {
    height: 50px;
}

.hamburger {
    height: 30px;

    margin-right: 10px;
}

.profileMobile {
    height: 30px;
    margin-right: 10px;
}

.searchIcon {
    height: 30px;

    margin-right: 10px;
}

.overlay {
    position: fixed;
    display: flex;

    width: 100%;
    height: 100%;

    top: 60px;
    left: 0;
    /* right: 0; */
    bottom: 0;

    background-color: var(--color-bg);

    z-index: 6;
}

.overlaySearchContent {
    display: flex;

    width: 100%;

    margin-right: auto;
    margin-left: auto;
    margin-top: 25px;

    justify-content: center;
}

.overlayListContent {
    width: 100%;
    margin-top: 10px;

    display: flex;
    flex-direction: column;

    text-align: right;
}

.signOut {
    font-weight: 600;
    margin-right: 10px;
    
    font-size: var(--font-size-large);
    color: var(--color-warningLight);

    cursor: pointer;
}

@media screen and (max-width: 1160px) {

    .navbarLink {
        font-weight: 600;
        font-size: var(--font-size-mediumSmall);
        margin-right: 20px;
        padding-top: 15px;
    }
    .activeNavbarLink {
        font-weight: 600;
        font-size: var(--font-size-mediumSmall);
        margin-right: 20px;
        padding-top: 15px;
    }

}

@media screen and (max-width: 850px) {

    .navbarLink {
        margin-right: 10px;
    }
    .activeNavbarLink {
        margin-right: 10px;
    }
}

@media screen and (max-width: 810px) {

    .navbarLink {
        font-size: var(--font-size-small);
        margin-right: 20px;
        padding-top: 20px;
    }
    .activeNavbarLink {
        font-size: var(--font-size-small);
        margin-right: 20px;
        padding-top: 20px;
    }
}

@media screen and (max-width: 940px) {

    .navbarLink {
        margin-right: 10px;
    }
    .activeNavbarLink {
        margin-right: 10px;
    }
}


@media screen and (max-width: 500px) {

    .navbarLink {
        font-weight: 600;
        font-size: var(--font-size-large);
        margin-right: 10px;
    }


}