@import "../vars.css";

.container {
    max-width: var(--max-width);

    margin-left: auto;
    margin-right: auto;
}

.header {
    margin-top: 25px;
    margin-left: 5%;
    margin-right: 5%;
}

.content {
    min-height: var(--min-height);
}

.errorMessage {
    margin: 20px;
    padding: 20px;
    
    height: 250px;

    font-size: var(--font-size-mediumLarge);
    color: var(--color-text);
}

/* EDIT MEDIA */

.header {

    color: var(--color-main);
    border-bottom: solid var(--color-main);
    text-align: left;
    font-size: var(--font-size-large);

    padding: 10px;
}

.activeType {
    border: none;
    border-radius: var(--border-radius-large);
    background-color: var(--color-mainLight);

    color: var(--color-mainDark);

    font-size: var(--font-size-tiny);
    font-weight: 550;
    font-family: "SanFransico";

    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 6px;
    padding-right: 6px;

    text-align: center;

    cursor: pointer;
}

.inactiveType {
    border: none;
    border-radius: var(--border-radius-large);
    background-color: var(--color-grayLight);

    color: var(--color-mainDark);
    border: none;

    font-size: var(--font-size-tiny);
    font-weight: 550;
    font-family: "SanFransico";

    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 6px;
    padding-right: 6px;

    text-align: center;

    cursor: pointer;
}

.inactiveType:hover {
    background-color: var(--color-gray);
}

.typeSelector {
    margin-left: 5%;
    margin-right: 5%;
    
    margin-top: 20px;
}

.typeObjects {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;

    gap: var(--grid-gap-small);
}

.instuctions {
    color: var(--color-text);
    font-size: var(--font-size-mediumLarge);

    margin-top: 20px;
}


@media screen and (max-width: 500px) {
    .content {
      min-height: 725px;
    }
}